import { environment } from '@env/environment';

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { Store } from '@ngxs/store';

import { InviteData } from '@shared/models/account.model';
import { VerifyUser } from '@shared/states/account.actions';
import { GoToSignIn } from '@shared/states/auth.actions';

@Component({
  selector: 'auth-signup-method',
  styleUrls: ['./signup-method.component.scss'],
  templateUrl: './signup-method.component.html',
})
export class SignupMethod implements OnInit, OnChanges {
  readonly environment = environment;

  @Input() googleError: string | null = null;
  @Input() emailError: string | null = null;
  @Input() inviteData: InviteData | null = null;
  @Input() welcomeText: string | null = null;
  @Output() select = new EventEmitter();

  constructor(private store: Store) {}

  ngOnInit() {
    if (this.inviteData) {
      const inviteKey = this.inviteData.$key;
      this.store.dispatch(new VerifyUser(inviteKey));
    }
  }

  ngOnChanges() {
    this.select.next(null);
  }

  signIn() {
    this.store.dispatch(new GoToSignIn());
  }

  signUpWithGoogle() {
    this.select.next('google');
  }

  signUpWithEmail() {
    this.select.next('email');
  }

  changeMethod() {
    this.select.next('change');
  }
}
